import React from 'react';

import { URL } from '../../variables';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

import './label.css'


class Redacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            redirect: false,
            path: '/processo',
            loading_save: false,
            show_success: false,
            show_error: false,
            msg_erro: '',
            show_lines_warning: false,
            show_warning_finish: false,
            tab: 0,
            pages_pdf: {},
            line_limit: 40,
            fontSize: null,
            redacao:{},
            resultado:{}
        }
        this.textoRef = React.createRef();
        this.tituloRef = React.createRef();
        this.lineCount = React.createRef();

        this.dropInput = this.dropInputFunction.bind(this);
        this.copyInput = this.copyInputFunction.bind(this);

        this.pasteInput = this.pasteInputFunction.bind(this);
        this.cutInput = this.cutInputFunction.bind(this);

        this.changeInput = this.changeInputFunction.bind(this);

        this.texto_keyup_ = this.texto_keyup.bind(this);
        this.texto_keydown_ = this.texto_keydown.bind(this);



        // this.videoRef=null;
    }

    changeInputFunction(e, force = false) {
            return;
        

    }

    dropInputFunction(event) {
        event.preventDefault();
    }

    copyInputFunction(event) {
        event.preventDefault();
    }

    pasteInputFunction(event) {
        event.preventDefault();
    }

    cutInputFunction(event) {
        event.preventDefault();
    }


    componentDidMount() {
        this.finish_redacao()
    }



    finish_redacao() {
       console.log('aaa')
        fetch(`${URL}api/faculdade/get_texto_redacao/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.match.params.token}`,
                'Content-Type': 'application/json',
            },
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.status == false) {

                    // let loading_resposta = { ...this.state.loading_resposta };
                    // loading_resposta[questao_id] = false;
                    this.setState({ loading_save: false, msg_erro: resp.msg, show_error: true });
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });

                }
                else {

                    this.setState({ redacao:resp.redacao,resultado:resp.resultado },()=>{
                        if (this.textoRef.current != null) {

                            if (this.textoRef.current.offsetHeight < this.textoRef.current.scrollHeight) {
                                // this.textoRef.current.selectionStart = 100000000;
                                // this.textoRef.current.selectionEnd = 100000000;
                                // this.setState({ show_lines_warning: true, msg: 'Você ultrapassou o limite de ' + this.state.redacao.number_lines + ' linhas!' });
                                // let ultrapassou_linhas = { ...this.props.ultrapassou_linhas };
                                // ultrapassou_linhas[this.state.redacao.id] = true;
                                // this.props.mudarAvisoLinhas(ultrapassou_linhas);
                            }
                            if (this.state.resultado?.texto[0] != '\t') {
                                let resultado = this.state.resultado
                                resultado.texto = '\t' + resultado.texto;
                                this.setState({resultado})
                            }
                            // this.changeInputFunction({ target: { value: this.state.resultado?.texto } }, true)
                            this.textoRef.current.scrollTop = this.textoRef.current.scrollHeight;
                            this.lineCount.current.scrollTop = this.textoRef.current.scrollHeight - this.textoRef.current.offsetHeight;
                        }
                    });

                }




            } catch (err) {
                console.log(err);

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });

            }

        })
            .catch((err) => {

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    lines() {
        let lines = []
        for (let i = 1; i <= 1000; i = i + 1) {
            lines.push(<p key={i} className="count_line" style={{ lineHeight: '38px',fontFamily: "'Courier New', Courier, monospace" }}>{i}</p>)
        }

        return lines;

    }

    title_keydown(e) {
        let keyCode = e.keyCode || e.which;

        if (keyCode == 13) {
            e.preventDefault();
        }
        if (keyCode == 9) {
            e.preventDefault();
        }
    }

    texto_keydown(e) {
            return;
       
    }

    speechText(text) {
        try {
            speechSynthesis.cancel();
            let msg = new SpeechSynthesisUtterance();
            let voices = window.speechSynthesis.getVoices().filter((item) => item.lang == 'pt-BR');
            console.log(voices)

            msg.voice = voices[9];
            // msg.volume = 1; // From 0 to 1
            // msg.rate = 1; // From 0.1 to 10
            // msg.pitch = 2; // From 0 to 2
            msg.text = text;
            msg.lang = 'pt-BR';
            this.speack_actual = speechSynthesis.speak(msg);
        } catch (e) {

        }

    }

    texto_keyup(e) {
            return;
        

    }

    render() {
        let indice = this.props.indice;
        let redacao = this.state.redacao;
        // redacao.resultado.finish=true;
        let index_carderno = 0;

        return (
            <div className='page_print' style={{width:'730px'}}>

                {this.state.redirect == true && <Redirect to={this.state.path} />}
                { <div >


                    



                    <div>
                   
                        {redacao?.show_titulo && <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <input spellCheck={false} onDrop={(event) => {
                                    event.preventDefault();
                                }} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => e.preventDefault()} ref={this.tituloRef} onKeyDown={this.title_keydown.bind(this)} value={this.state.resultado?.titulo} onChange={(e) => {
                                        return;
                                    
                                }} name="titulo" maxLength={60} placeholder="Título" autoComplete={'off'} className="form-control" style={{ marginLeft: '3rem', borderTop: '0px', borderRight: '0px', borderLeft: '0px', paddingLeft: 0, paddingRight: 0, width: '678px', textAlign: 'center', fontSize: '18px', fontFamily: "'Courier New', Courier, monospace", fontWeight: 'bold' }} />
                            </div>
                        </div>}
                        <div className=" container_scroll d-flex justify-content-center"
                            style={{margin:0, padding: '1.25rem', overflowX: 'scroll', flexWrap: 'inherit',paddingRight:0,paddingBottom:0,paddingTop:0 }}>
                            <div style={{ width: '710px', paddingRight: 0, paddingLeft: '2rem', zIndex: 1, overflowX: 'scroll' }}>
                                <textarea spellCheck={false} onDrop={this.dropInput} onCopy={this.copyInput} onPaste={this.pasteInput} onCut={this.cutInput} onChange={this.changeInput} value={this.state.resultado?.texto} onKeyUp={this.texto_keyup_} onKeyDown={this.texto_keydown_} ref={this.textoRef} name="texto" autoComplete="off" className="lines" style={{ width: '678px', whiteSpace: 'pre-wrap',fontFamily: "'Courier New', Courier, monospace" }} maxLength={10000} rows={this.state.redacao.number_lines} role="textbox" />
                            </div>
                            <div style={{
                                paddingLeft: 0, paddingRight: 0,
                                // height: '1152px', 
                                height: Math.ceil((1152 / 30) * this.state.redacao.number_lines),
                                overflow: 'hidden', position: 'absolute',
                                width: '710px',
                                zIndex: 0, paddingTop: '0.5rem'
                            }} ref={this.lineCount}
                            >
                                {this.lines()}

                            </div>


                        </div>



                    </div>

                </div>}




            </div>
        );
    }

    onConfirm() {

    }

    onCancel() {

    }


}

const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        redacoes: state.AppReducer.redacoes,
        provas: state.AppReducer.provas,
        questionarios: state.AppReducer.questionarios,
        ultrapassou_linhas: state.AppReducer.ultrapassou_linhas
    }
);

export default connect(mapsStateToProps, {  })(Redacao);
