import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdDownload, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';

import './modal.css';


const Downloads = props => {
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_downloads, setLoadingdownloads] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [downloads, setdownloads] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });

    const dispatch = useDispatch();

    const pesquisarTimeout = useRef();


    const Getdownloads = async (page, search) => {

        let response = await Request.Get(`faculdade/get_downloads/${props.faculdade_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setdownloads(response.downloads);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingdownloads(false);


        }
    }



    useEffect(() => {
        Getdownloads(1, search_oficial);
    }, []);
    return (
        <div className="row">

            
            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam os downloads do seletivo</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">
                    <Search className="mt-2" placeholder="Pesquisar pelo nome do arquivo" ValueChange={value => {
                            clearTimeout(pesquisarTimeout.current);
                            setSearchOficial(value);

                            pesquisarTimeout.current = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingdownloads(true);

                                Getdownloads(1, value)

                            }, 500);
                        }} />



                        <div className="d-flex mt-2">
                            {/* {<div onClick={() => {
                                if (loading_zip == false) {
                                    setLoadingZip(true);
                                    setMessageZip('Criando .zip para download');
                                    GetCandidatosZip();
                                }
                                else {

                                }
                            }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {loading_zip == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar todos relatórios</p></Button>}
                                {loading_zip == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                    </div>
                                </div>}
                            </div>}

                            <Link to={{
                                pathname: "/downloads_vinculados/register/" + processo_id,
                            }} style={{ textDecoration: "none" }}>
                                <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar downloads</p></Button>
                            </Link> */}

                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_downloads == false && downloads.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            {/* <td>{item.user.email}</td> */}

                                            {/* <td>{moment(item.nascimento).format('DD/MM/YYYY')}</td> */}

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>

                                                <a target="_blank" href={item.url} >

                                                    <span

                                                        className="inline-flex align-center jcc bg-success icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Download arquivo"
                                                    >
                                                        <MdDownload size={17} color="white" />
                                                    </span>
                                                </a>
                                                {/* <span
                                                    onClick={() => {
                                                        GetRelatórioCandidato(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar relatório"
                                                >
                                                    <MdFileDownload size={17} color="white" />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        GetVideoCandidato(item);

                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar Vídeo"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span>

                                                <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular downloads"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_downloads == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_downloads == false && downloads.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum download registrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingdownloads(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getdownloads(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_downloads={this.get_downloads.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}




            </div>

        </div>
    )
}

export default Downloads;
