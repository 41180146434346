import { Box,CircularProgress} from "@material-ui/core";
import { useQuery } from "react-query";
import { createContext, useCallback, useEffect, useRef, useState } from "react";

import Cards from "./Cards";
import QuickActions from "./QuickActions";
import { logout } from "../../../actions/AppActions";
import { useDispatch } from "react-redux";
import Request from "../../../Request";
import { useParams } from "react-router-dom";

export const DashboardContext = createContext();

const Dashboard = () => {

    const dispatch = useDispatch();
    const [data,setData] = useState({});
    const [isLoading,setLoading] = useState(true);
    const timeout_dashboard = useRef();

    const get_dashboard = useCallback(async(faculdade_id)=>{
        let response = await Request.Get(`faculdade/dashboard/${faculdade_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        setData(response);
        setLoading(false);

        timeout_dashboard.current = setTimeout(()=>{
            get_dashboard(faculdade_id);
        },30000);
    },[]);

    const { faculdade_id } = useParams()


    // let { isLoading, data } = useQuery("dashboard", async () => await get_dashboard(faculdade_id));

    // useEffect(()=>{
    //     setLoading(isLoading);
    //     data={}
    //     console.log('loading');

    // },[faculdade_id]);


    useEffect(()=>{
        setLoading(true);
        get_dashboard(faculdade_id);
        return ()=>{
            clearTimeout(timeout_dashboard.current)
        }

    },[]);

    return (
        <Box>
            <DashboardContext.Provider
                value={{
                    isLoading:isLoading,
                    ...data
                }}
            >
                {<Cards/>}

                {/* <QuickActions/> */}
            </DashboardContext.Provider>
        </Box>
    );
}

export default Dashboard;