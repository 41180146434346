import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaArrowLeft, FaArrowRight, FaListAlt, FaVideo } from "react-icons/fa";
import { MdAccessTime, MdArrowLeft, MdArrowRight, MdArrowRightAlt, MdDelete, MdModeEdit, MdPlayArrow, MdScreenShare, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const Relatorio = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_relatorio, setLoadingrelatorio] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [logs, setlogs] = useState([]);
    const [deixou_aplicacao, setDeixouAplicacao] = useState(0);
    const [logs_saidas, setLogsSaidas] = useState([]);
    const [logs_warning, setLogsWarning] = useState([]);

    const [acoes_relatadas, setAcoesRelatadas] = useState([]);

    const [loading_allow,setLoadingAllow] = useState(false);
    const [show_btn_allow,setShowBtnAllow] = useState(false);



    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });


    const [current_page, setCurrentPage] = useState(1);
    const [last_page, setLastPage] = useState(1);

    const [search, setSearch] = useState('');
    const { candidato_id, candidato_name, processo_id } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [relatorio_id, setrelatorioId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getrelatorio = async (page, search) => {

        let response = await Request.Get(`faculdade/show_relatorio_aluno/${processo_id}/${candidato_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }

            setlogs(response.logs);
            setLogsSaidas(response.logs_saidas);
            setLogsWarning(response.logs_warning);

            setDeixouAplicacao(response.deixou_aplicacao);

            setAcoesRelatadas(response.acoes_relatadas);
            // setShowBtnAllow(response.show_btn_access);


         

            // setPagination({...response.pagination});

            setLoadingScreen(false);
            setLoadingrelatorio(false);


    }

    const [relatorio_select, setVideoSelect] = useState(null);

    const Deleterelatorio = async (relatorio_id) => {

        let response = await Request.Delete(`faculdade/delete_relatorio/${relatorio_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getrelatorio(current_page, search_oficial);



    }

    const AccessLiberation = async () => {

        let response = await Request.Get(`faculdade/allow_access/${processo_id}/${candidato_id}`);
        console.log(response);
        setLoadingAllow(false);

        if (response === undefined) return;
        if (response.message == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'provas_processo_'+processo_name+'.zip');
        // }
        else {
            setLoadingScreen(true);

            Getrelatorio(1, search_oficial);
            
            // saveAs(response, 'analises_candidatos_' + processo_name + '.xlsx');


        }
    }

    useEffect(() => {
        Getrelatorio(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui fica o relatorio do candidato "{candidato_name}"</p>
                   
                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}
                    {<div className="d-flex mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            {<div onClick={() => {
                                if (loading_allow == false) {
                                    setShowWarning(true);
                                }
                                else {

                                }
                            }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {loading_allow == false && <Button variant="success" className="flex align-center jcc me-2"><MdAccessTime style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Liberar/Resetar acesso do candidato</p></Button>}
                                {loading_allow == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <CircularProgress size={15} />
                                </div>
                                </div>}
                            </div>}


                        </div>}
                    <div className=" mb-4 mt-4">
                    <p className="mt-0 header-title" style={{  color: 'black',marginBottom:0,fontSize: '1.1rem' }}>Logs do sistema:</p>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Ação</th>
                                        <th>De</th>
                                        <th>Até</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_relatorio == false && logs.map((item, id) => (

                                        <tr style={{  }} key={id}>
                                            <td>{item.acao}</td>
                                            <td>{item.hora_inicio}</td>
                                            <td>{item.hora_fim}</td>

                                           
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_relatorio == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_relatorio == false && logs.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum registro encontrado</p>

                        </div>
                    </div>}

                    <div className="row w-100">
                        <div className="col-12">
                            <p ><b style={{fontWeight:600}}>Saiu da página da aplicação: </b> {deixou_aplicacao}</p>
                        </div>
                    </div>


                    <div className=" mb-4 mt-4">
                        <p className="header-title" style={{  color: 'black',marginBottom:0,fontSize: '1.1rem' }}>Ações reportadas:</p>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>
                                    <th>Fiscal</th>

                                        <th>Ação</th>
                                        <th>Horário</th>
                                        <th>Ações</th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {acoes_relatadas.map((item, id) => (

                                        <tr style={{  }} key={id}>
                                            <td>{item.fiscal.name}</td>
                                            <td>{item.acao}</td>
                                            <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                            <td>
                                                {item.video.length>0 && <a
                                                target="_blank" href={item.video[0].url}
                                                >
                                                <span
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver Vídeo da Câmera"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span>
                                                </a>}
                                                {item.screen.length>0 && <a
                                                target="_blank" href={item.screen[0].url}
                                                >
                                                <span
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver Vídeo da Tela"
                                                >
                                                    <MdScreenShare size={17} color="white" />
                                                </span>
                                                </a>}
                                                {item.screen.length==0 && item.video.length==0 && <span>-</span>}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                           
                        </div>
                    </div>
                    {acoes_relatadas.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma ação registrada</p>

                        </div>
                    </div>}



                    

                    <div className=" mb-4 mt-4">
                        <p className="mt-0 header-title" style={{  color: 'black',marginBottom:0,fontSize: '1.1rem' }}>Registros de Saída:</p>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Ação</th>
                                        <th>Horário</th>
                                        <th>Ações</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {logs_saidas.map((item, id) => (

                                        <tr style={{  }} key={id}>
                                            <td>{item.acao}</td>
                                            <td>{item.hora_inicio}</td>
                                            <td>
                                                {item.video.length>0 && <a
                                                target="_blank" href={item.video[0].url}
                                                >
                                                <span
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver Vídeo da Câmera"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span>
                                                </a>}
                                                {item.screen.length>0 && <a
                                                target="_blank" href={item.screen[0].url}
                                                >
                                                <span
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver Vídeo da Tela"
                                                >
                                                    <MdScreenShare size={17} color="white" />
                                                </span>
                                                </a>}
                                                {item.screen.length==0 && item.video.length==0 && <span>-</span>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                           
                        </div>
                    </div>
                    {logs_saidas.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum registro encontrado</p>

                        </div>
                    </div>}



                    <div className=" mb-4 mt-4">
                        <p className="mt-0 header-title" style={{  color: 'black',marginBottom:0,fontSize: '1.1rem' }}>Avisos de restrição de horário/duas ou mais telas/permissões:</p>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Ação</th>
                                        <th>Horário</th>
                                        <th>Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {logs_warning.map((item, id) => (

                                        <tr style={{  }} key={id}>
                                            <td>{item.acao}</td>
                                            <td>{item.hora_inicio}</td>
                                            <td>
                                                {item.video?.length>0 && <a
                                                target="_blank" href={item.video[0].url}
                                                >
                                                <span
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver Vídeo da Câmera"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span>
                                                </a>}
                                                {item.screen?.length>0 && <a
                                                target="_blank" href={item.screen[0].url}
                                                >
                                                <span
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver Vídeo da Tela"
                                                >
                                                    <MdScreenShare size={17} color="white" />
                                                </span>
                                                </a>}
                                                {item.screen?.length==0 && item.video?.length==0 && <span>-</span>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                           
                        </div>
                    </div>
                    {logs_warning.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum aviso encontrado</p>

                        </div>
                    </div>}

                   

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingAllow(true);
                        AccessLiberation();

                        // setLoadingrelatorio(true);
                        // Deleterelatorio(relatorio_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo liberar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao liberar esse candidato, ele terá acesso a avaliação caso ele não tenha entrado durante o intervalo de horário determinado, ou caso ele já tenha finalizado a avaliação
                </SweetAlert>


            </div>

        </div>
    )
}

export default Relatorio;
